import { db } from '../firebase';
import {
  doc,
  updateDoc,
  serverTimestamp,
  FirestoreError
} from 'firebase/firestore';
import { WordItem } from '../interfaces/wordItem';
import {
  usersCollectionName,
  usersEnglishWordsCollectionName
} from '../globalDefinition';
import { WordError } from '../core/errors/wordError';
import * as Sentry from '@sentry/vue';

export const updateUserWord = async (
  authId: string,
  wordItem: WordItem
): Promise<WordItem> => {
  if (wordItem.id == null) {
    throw new WordError('Word-updateUserWord-' + "Word item's id is null");
  }

  const wordDoc = doc(
    db,
    usersCollectionName,
    authId,
    usersEnglishWordsCollectionName,
    wordItem.id
  );

  const newData = {
    word: wordItem.word,
    definition: wordItem.definition,
    belongTo: wordItem.belongTo,
    memo: wordItem.memo,
    isFavorite: wordItem.isFavorite,
    rememberedDate: wordItem.rememberedDate,
    updatedAt: serverTimestamp()
  };
  try {
    await updateDoc(wordDoc, newData);
  } catch (error: unknown) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new WordError(
        'Word-updateUserWord-' + error.message
      );
      throw firestoreError;
    }
    const e = new WordError('Word-updateUserWord-' + 'Unexpected exception');

    throw e;
  }

  return {
    ...newData,
    updatedAt: new Date(),
    createdAt: wordItem.createdAt,
    id: wordItem.id
  };
};
