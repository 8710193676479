import { db } from '../firebase';
import { doc, updateDoc, FirestoreError } from 'firebase/firestore';
import { WordItem } from '../interfaces/wordItem';
import {
  usersCollectionName,
  usersEnglishWordsCollectionName
} from '../globalDefinition';
import { WordError } from '../core/errors/wordError';
import * as Sentry from '@sentry/vue';

export const updateUserWordFavorirte = async (
  authId: string,
  wordItem: WordItem
): Promise<void> => {
  try {
    if (wordItem.id == null) {
      throw new WordError(
        'Word-updateUserWordFavorirte-' + "Word item's id is null"
      );
    }

    const wordDoc = doc(
      db,
      usersCollectionName,
      authId,
      usersEnglishWordsCollectionName,
      wordItem.id
    );

    await updateDoc(wordDoc, { isFavorite: !wordItem.isFavorite });
    wordItem.isFavorite = !wordItem.isFavorite;
  } catch (error: unknown) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new WordError(
        'Word-updateUserWordFavorirte-' + error.message
      );
      throw firestoreError;
    }
    const e = new WordError(
      'Word-updateUserWordFavorirte-' + 'Unexpected exception'
    );

    throw e;
  }
};
