export default {
  common: {
    header: {
      userName: 'ユーザー名:',
      menuTitle: 'メニュー',
      linkSetting: '設定',
      linkLogout: 'ログアウト',
      noUserName: 'ユーザ名未登録'
    },
    footer: {
      linkAbout: 'サービスについて',
      linkPrivacyAndPolicy: 'プライバシーとポリシーについて',
      linkContact: 'お問い合わせ'
    },
    wordMenuBar: {
      word: { title: '単語一覧' },
      favoriteWord: { title: 'お気に入りの単語一覧' }
    },
    accountDeleteModal: {
      notificationTitle: '注意',
      notificationMessage:
        'アカウントの削除をおこなった際、ご利用の本アカウントおよび本アカウントに紐づくデータが削除されます。 内容を理解した上でアカウントの削除を行う場合は入力フォームに"削除"を入力し、削除ボタンを押してください',
      deleteAccountButtonText: 'アカウントを削除する',
      closeModalButtonText: '閉じる',
      validation: {
        formTitle: '入力フォーム',
        deleteConfirmText: '削除',
        deleteConfirmPlaceholder: '"削除"と入力してください。'
      }
    }
  },
  loginPage: {
    googleLoginButtonText: 'Googleでログイン'
  },
  wordListPage: {
    title: '単語一覧',
    searchBar: {
      placeholder: '単語を検索'
    },
    wordRegister: {
      title: '単語の登録',
      wordTextPlaceholder: '単語',
      definitionTextPlaceholder: '意味や定義',
      memoTextPlaceholder: 'メモ',
      registerButtonText: '登録する'
    },
    wordDetailModal: {
      edit: '編集',
      close: '閉じる',
      delete: 'この単語を削除',
      rememberButtonText: 'この単語を覚えた?',
      forgetButtonText: 'この単語を忘れた?',
      word: '単語',
      description: '意味や定義',
      memo: 'メモ',
      notRegisterd: '登録されていません',
      rememberdDate: '覚えた日を記録',
      failedToGetWord: '単語の取得に失敗しました。'
    },
    wordEditModal: {
      cancel: 'キャンセル',
      update: '更新',
      word: '単語',
      description: '意味や定義',
      memo: 'メモ'
    }
  },
  favoriteWordListPage: {
    title: 'お気に入りの単語一覧',
    notExist: 'まだ、お気に入りの単語が登録されていません。'
  },
  settingPage: {
    title: '設定',
    userName: '名前',
    createdAt: '登録日',
    updatedAt: '最終更新日',
    editNameButtonText: '編集',
    updateNameButtonText: '更新',
    deleteAccountButtonText: 'アカウントの削除'
  },
  notFoundPage: {
    title: '404 お探しのページは見つかりませんでした.'
  },
  messages: {
    word: {
      getUserWords: {
        failedToGetWordList: '単語一覧の取得に失敗しました。'
      },
      registerWord: {
        failedToAddWord: '単語の登録に失敗しました。',
        successedToAddWord: '単語の登録に成功しました。'
      },
      updateWord: {
        failedToUpdateWord: '単語の更新に失敗しました',
        successedToUpdateWord: '単語の更新に成功しました。'
      },
      deleteWord: {
        failedToDeleteWord: '単語の削除に失敗しました。',
        successedToDeleteWord: '単語の削除に成功しました。'
      }
    },
    favoriteWord: {
      getUserWords: {
        failedToGetWordList: '単語一覧の取得に失敗しました。'
      }
    },
    user: {
      getUserInfo: {
        failedToGetUserInfo: 'ユーザー情報の取得に失敗しました。'
      },
      updateUserName: {
        failedToUpdateUserName: 'Failed to update user name.',
        successedToUpdateUserName: 'Success to update user name.'
      },
      deleteUser: {
        failedToDeleteUserAcount: 'アカウントの削除に失敗しました。',
        successedToDeleteUserAccount:
          'アカウントの削除に成功しました。 今までご利用ありがとうございました。'
      }
    },
    auth: {
      login: {
        failedToLogin: 'ログインに失敗しました。',
        successedToLogin: 'ログインに成功しました。'
      },
      logout: {
        failedToLogout: 'ログアウトに失敗しました。',
        successedToLogout: 'ログアウトに成功しました。'
      }
    }
  }
};
