<template>
  <div
    id="word-detail-modal"
    class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto z-10"
  >
    <div
      class="modal fixed top-0 left-0 w-full h-full bg-zinc-900 opacity-50"
      tabindex="-1"
      aria-labelledby="app-modal"
      aria-hidden="true"
      @click="emit('action', false)"
    />
    <div
      class="modal-dialog h-screen w-screen flex justify-center items-center"
    >
      <div
        class="modal-content overflow-y-auto hidden-scrollbar border-none container mx-auto md:w-5/12 md:h-auto shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current p-3"
      >
        <div class="modal-header px-2 flex justify-between">
          <span class="text-lg font-bold">{{
            $t('common.accountDeleteModal.notificationTitle')
          }}</span>
          <button
            type="button"
            class="btn-close box-content font-bold text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            @click="emit('action', false)"
          >
            {{ $t('common.accountDeleteModal.closeModalButtonText') }}
          </button>
        </div>
        <div class="modal-body mt-2 p-4 flex-grow">
          <i18n-t
            tag="p"
            class="underline"
            keypath="common.accountDeleteModal.notificationMessage"
            ><template #newline><br /></template
          ></i18n-t>
          <div class="flex justify-items-start mt-8">
            <FormValidation
              :placeholder="placeholder"
              v-if="validateForm"
              @callback="checkDeleteConfirm"
            />
          </div>
        </div>
        <div class="flex justify-center items-center sticky bottom-0 mt-8">
          <button
            :class="[
              buttonCursorStyle,
              'btn-close w-64 p-2 border-2 rounded box-content text-white bg-red-600'
            ]"
            :disabled="isNotDeleteable"
            v-on:click="deleteAccountHandller"
          >
            {{ $t('common.accountDeleteModal.deleteAccountButtonText') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import FormValidation from './FormValidation.vue';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { deleteUserAccount } from '../../modules/deleteUserAccount';
import { useAppStore } from '../../stores/AppStore';
import { getAuth } from 'firebase/auth';
import { router } from '../../routes';

const { t } = useI18n();

type Options = {
  validateForm?: boolean;
};

type Props = {
  isShown: boolean;
  options?: Options;
};
const props = defineProps<Props>();
interface Emits {
  (e: 'action', status: boolean): void;
}
const emit = defineEmits<Emits>();

// eslint-disable-next-line vue/no-setup-props-destructure
const { options } = props;

const isNotDeleteable = ref(true);

const appStore = useAppStore();

const validateForm = options?.validateForm ?? false;

const placeholder = t(
  'common.accountDeleteModal.validation.deleteConfirmPlaceholder'
);

const deleteConfirmText = t(
  'common.accountDeleteModal.validation.deleteConfirmText'
);
const checkDeleteConfirm = (inputContent: string) => {
  return (isNotDeleteable.value = inputContent != deleteConfirmText);
};
const deleteAccountHandller = async () => {
  try {
    const auth = getAuth();
    const authId = auth.currentUser?.uid;
    if (!authId) {
      router.replace('/login');
      return;
    }
    await deleteUserAccount(authId);
    await auth.currentUser.delete();
    router.replace('/');
    appStore.setAppMessage({
      type: 'success',
      msg: 'messages.user.deleteUser.successedToDeleteUserAccount'
    });
  } catch (e) {
    appStore.setAppMessage({
      type: 'denger',
      msg: 'messages.user.deleteUser.failedToDeleteUserAccount'
    });
  }
};

const buttonCursorStyle = computed(() =>
  isNotDeleteable.value
    ? 'cursor-not-allowed md:opacity-70'
    : 'cursor-pointer md:opacity-100'
);
</script>
