import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  browserSessionPersistence,
  setPersistence
} from 'firebase/auth';
import * as Sentry from '@sentry/vue';
import { serverTimestamp, setDoc, doc } from '@firebase/firestore';
import { db } from '../firebase';
import { usersCollectionName } from '../globalDefinition';
import { AppUser } from '../interfaces/AppUser';
import { useAppStore } from '../stores/AppStore';
import { AuthError } from '../core/errors/authError';
import { getServiceUser } from './getServiceUser';

export const login = async () => {
  const appStore = useAppStore();
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  await signInWithPopup(auth, provider)
    .then(async (result) => {
      const authUser = result.user;
      if (authUser) {
        const serviceUser = await getServiceUser(authUser.uid);
        setPersistence(auth, browserSessionPersistence);
        if (serviceUser) {
          appStore.currentUser = serviceUser;
        } else {
          const createdAt = serverTimestamp();
          const updatedAt = serverTimestamp();
          const docRef = doc(db, usersCollectionName, authUser.uid);
          await setDoc(docRef, {
            name: authUser.displayName,
            createdAt,
            updatedAt,
            wordLanguages: ['English']
          });
          const currentUser: AppUser = {
            id: authUser.uid,
            name: authUser.displayName,
            createdAt: new Date(),
            updatedAt: new Date()
          };
          appStore.currentUser = currentUser;
        }
      }
    })
    .catch((e) => {
      Sentry.captureException(e);
      throw new AuthError('Login Failure');
    });
};
