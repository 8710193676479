export default {
  common: {
    header: {
      userName: 'Username:',
      menuTitle: 'Menu',
      linkSetting: 'Setting',
      linkLogout: 'Logout',
      noUserName: 'No username'
    },
    footer: {
      linkAbout: 'About Us',
      linkPrivacyAndPolicy: 'About Privacy and Policy',
      linkContact: 'Contact'
    },
    wordMenuBar: {
      word: { title: 'Words' },
      favoriteWord: { title: 'Favorite words' }
    },
    accountDeleteModal: {
      notificationTitle: 'Notice',
      notificationMessage:
        "Your account and your data will be deleted. If you want to delete it, type 'Delete' on the text field",
      deleteAccountButtonText: 'Delete',
      closeModalButtonText: 'Close',
      validation: {
        formTitle: 'Input form',
        deleteConfirmText: 'Delete',
        deleteConfirmPlaceholder: 'Type "delete" here'
      }
    },
    searchBar: {
      placeholder: 'search word'
    }
  },
  loginPage: {
    googleLoginButtonText: 'Sign in with Google'
  },
  wordListPage: {
    title: 'Word List',
    searchBar: {
      placeholder: 'Search word(s)'
    },
    register: {
      title: 'New Word.',
      wordTextPlaceholder: 'Word',
      definitionTextPlaceholder: 'Definition',
      memoTextPlaceholder: 'Memo',
      registerButtonText: 'Register'
    },
    wordRegister: {
      title: 'Register a word',
      wordTextPlaceholder: 'Word',
      definitionTextPlaceholder: 'Definition',
      memoTextPlaceholder: 'Memo',
      registerButtonText: 'Register'
    },
    wordDetailModal: {
      edit: 'Edit',
      close: 'Close',
      delete: 'Delete',
      rememberButtonText: 'Remembered this word',
      forgetButtonText: 'Forgot this word',
      word: 'Word',
      description: 'Description',
      memo: 'Memo',
      notRegisterd: 'Not registered',
      rememberdDate: 'Remembered Date',
      failedToGetWord: 'Cannot get word.'
    },
    wordEditModal: {
      cancel: 'Cancel',
      update: 'Update',
      word: 'Word',
      description: 'Description',
      memo: 'Memo'
    }
  },
  favoriteWordListPage: {
    title: 'Favorite Word',
    notExist: 'No favorite word yet.'
  },
  settingPage: {
    title: 'Setting',
    userName: 'Name',
    createdAt: 'Registered at',
    updatedAt: 'Updated at',
    editNameButtonText: 'Edit',
    updateNameButtonText: 'Update',
    deleteAccountButtonText: 'Delete account'
  },
  notFoundPage: {
    title: '404 Not Found a Page.'
  },
  messages: {
    word: {
      getUserWords: {
        failedToGetWordList: 'Failed to get word list.'
      },
      registerWord: {
        failedToAddWord: 'Failed to add new word.',
        successedToAddWord: 'New word successfully added.'
      },
      updateWord: {
        failedToUpdateWord: 'Failed to update',
        successedToUpdateWord: 'Update Sucessful'
      },
      deleteWord: {
        failedToDeleteWord: 'Failed to delete.',
        successedToDeleteWord: 'Successfully deleted.'
      }
    },
    favoriteWord: {
      getUserWords: {
        failedToGetWordList: 'Failed to get favorite word list.'
      }
    },
    user: {
      getUserInfo: {
        failedToGetUserInfo: 'Failed to get your infomation.'
      },
      updateUserName: {
        failedToUpdateUserName: 'Failed to update username.',
        successedToUpdateUserName: 'Success to update username.'
      },
      deleteUser: {
        failedToDeleteUserAcount:
          'Failed to delete your account. Please contact us from contact page.',
        successedToDeleteUserAccount:
          'Account is now deleted. Thank you you for using LingPocket. '
      }
    },
    auth: {
      login: {
        failedToLogin: 'Failed to login',
        successedToLogin: 'Successfully logged in'
      },
      logout: {
        failedToLogout: 'Failed to logout',
        successedToLogout: 'Logged out successfully'
      }
    }
  }
};
