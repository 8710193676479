import { defineStore } from 'pinia';
import { Message } from '../interfaces/message';
import { WordItem } from '../interfaces/wordItem';

interface FavoriteWordPageState {
  message: Message | null;
  favoriteWords: WordItem[];
  isLoading: boolean;
}
export const useFavoriteWordPageStore = defineStore('favoriteWordPageStore', {
  state: (): FavoriteWordPageState => {
    return { message: null, favoriteWords: [], isLoading: true };
  },
  actions: {
    setMessage(msg: Message | null) {
      this.message = msg;
    },
    setFavoriteWords(words: WordItem[]) {
      this.favoriteWords = words;
    },
    setLoadingStatus(status: boolean) {
      this.isLoading = status;
    }
  }
});
