import { doc, getDoc, Timestamp } from '@firebase/firestore';
import { db } from '../firebase';
import { usersCollectionName } from '../globalDefinition';
import { AppUser } from '../interfaces/AppUser';
import { FirestoreError } from 'firebase/firestore';
import { UserError } from '../core/errors/userError';
import * as Sentry from '@sentry/vue';

export const getServiceUser = async (
  authId: string
): Promise<AppUser | null> => {
  const docRef = doc(db, usersCollectionName, authId);
  let docSnap;
  try {
    docSnap = await getDoc(docRef);
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new UserError(
        'User-getServiceUser-' + error.message
      );
      throw firestoreError;
    }
    const e = new UserError('User-getServiceUser-' + 'Unexpected exception');

    throw e;
  }
  if (docSnap.exists()) {
    const data = docSnap.data({ serverTimestamps: 'estimate' });
    const name = data['name'];
    const createdAt = (data['createdAt'] as Timestamp).toDate();
    const updatedAt = (data['updatedAt'] as Timestamp).toDate();
    const currentUser: AppUser = {
      id: data.id,
      name,
      createdAt,
      updatedAt
    };
    return currentUser;
  } else {
    return null;
  }
};
