<script setup lang="ts">
import { ref, computed } from 'vue';
import { router } from '../../routes';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import { useAppStore } from '../../stores/AppStore';
import { useI18n } from 'vue-i18n';

interface UserMenu {
  title: string;
  method: () => Promise<void>;
}

const appStore = useAppStore();

const { t } = useI18n();

const userMenus: UserMenu[] = [
  {
    title: 'linkSetting',
    method: async () => {
      router.push({ name: 'Setting' });
    }
  },
  {
    title: 'linkLogout',
    method: async () => {
      try {
        await signOut(auth);
        appStore.setCurrentUser(null);
        appStore.setAppMessage({
          type: 'success',
          msg: 'messages.auth.logout.successedToLogout'
        });
        router.push({ name: 'Login' });
      } catch (e) {
        appStore.setAppMessage({
          type: 'denger',
          msg: 'messages.auth.logout.failededToLogout'
        });
      }
    }
  }
];

const isOpenMenu = ref(false);

const showChangeStatusOfMenu = () => {
  isOpenMenu.value = !isOpenMenu.value;
};
const currentUser = computed(() => appStore.currentUser);
const userName = computed(
  () => appStore.currentUser?.name ?? t('common.header.noUserName')
);
</script>

<template>
  <nav
    :class="[
      'w-full flex items-center justify-between flex-wrap bg-zinc-700 p-6',
      isOpenMenu ? 'h-auto' : 'h-20'
    ]"
  >
    <div class="flex items-center flex-shrink-0 text-white mr-6">
      <router-link class="font-semibold text-xl tracking-tight" to="/">
        LingPocket
      </router-link>
    </div>
    <div v-if="currentUser" class="block md:hidden">
      <button
        class="flex items-center px-3 py-2 border rounded text-white hover:text-white border-white"
        @click="showChangeStatusOfMenu"
      >
        <span v-if="!isOpenMenu">{{ $t('common.header.menuTitle') }}</span>
        <span v-else>X</span>
      </button>
    </div>
    <div :class="['w-full flex-grow flex items-center md:w-auto ']">
      <div :class="[!isOpenMenu && 'hidden']">
        <div v-if="currentUser" class="hidden md:block text-sm md:flex-grow">
          <router-link
            class="text-base text-white p-2 opacity-75 hover:opacity-100 hover:font-bold"
            to="/"
          >
            {{ $t('wordListPage.title') }}
          </router-link>
        </div>
        <ul v-if="currentUser" class="md:hidden mx-auto text-white">
          <li>
            <router-link class="text-base text-white p-2" to="/">
              {{ $t('wordListPage.title') }}
            </router-link>
          </li>
          <li
            v-for="menu in userMenus"
            :key="menu.title"
            :class="[
              'p-2 border-0',
              menu.title === 'linkLogout' && 'text-rose-600'
            ]"
            @click="menu.method"
          >
            <span class="hover:font-bold hover:border-b-2">
              {{ $t(`common.header.${menu.title}`) }}
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div class="group hidden md:block relative text-white hover:shadow-md">
      <div v-if="currentUser" class="flex">
        <span class="mr-1 p-1 text-base">{{
          $t('common.header.userName')
        }}</span>
        <span class="p-1 font-bold text-xl">{{ userName }}</span>
      </div>
      <div
        class="mx-auto h-auto w-36 rounded invisible group-hover:visible absolute top-8 bg-zinc-600 right-0 cursor-pointer"
      >
        <ul class="mx-auto">
          <li
            v-for="menu in userMenus"
            :key="menu.title"
            :class="[
              'p-2 border-0',
              menu.title === 'linkLogout' && 'text-rose-600 opacity-75'
            ]"
            @click="menu.method"
          >
            <span class="hover:font-bold hover:border-b-2">
              {{ $t(`common.header.${menu.title}`) }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
