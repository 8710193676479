import { db } from '../firebase';
import { updateDoc, doc, FirestoreError } from 'firebase/firestore';
import { usersCollectionName } from '../globalDefinition';
import { UserError } from '../core/errors/userError';
import * as Sentry from '@sentry/vue';

export const updateUserName = async (
  authId: string,
  name: string
): Promise<void> => {
  const updateRef = doc(db, usersCollectionName, authId);
  try {
    await updateDoc(updateRef, {
      name
    });
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new UserError(
        'User-updateUserName-' + error.message
      );
      throw firestoreError;
    }
    const e = new UserError('User-updateUserName-' + 'Unexpected exception');

    throw e;
  }
};
