import { initializeApp, getApps, FirebaseOptions } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

const firebaseConfig: FirebaseOptions = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SSENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID
};
if (
  (import.meta.env.MODE == 'staging' ||
    import.meta.env.MODE === 'development') &&
  firebaseConfig.measurementId
) {
  delete firebaseConfig['measurementId'];
}

const firebaseApps = getApps();
let app;

if (firebaseApps.length > 0) {
  app = firebaseApps[0];
} else {
  app = initializeApp(firebaseConfig);
}

if (import.meta.env.MODE == 'production') getAnalytics(app);

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);
functions.region = 'asia-northeast1';

if (import.meta.env.MODE === 'development') {
  connectAuthEmulator(
    auth,
    'http://' + import.meta.env.VITE_FIREBASE_DEV_AUTH_SERVER + '/auth'
  );
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
}

export { auth, db, functions };
