<script setup lang="ts">
import { computed } from 'vue';
import { searchWordStore } from '../../stores/search-word.store';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const placeholder = t('wordListPage.searchBar.placeholder');

const serchModel = computed({
  get: () => {
    return searchWordStore.searchText;
  },
  set: (value: string) => {
    searchWordStore.searchText = value;
  }
});
</script>

<template>
  <div class="flex border-2 rounded">
    <div class="pt-3 px-2">
      <ion-icon name="search-outline" />
    </div>
    <div class="w-full">
      <input
        v-model="serchModel"
        data-testid="test-search-input"
        type="text"
        :placeholder="placeholder"
        class="w-full text-lg h-10 p-2 outline-0"
      />
    </div>
  </div>
</template>

<style css scoped>
ion-icon {
  font-size: 16px;
}
</style>
