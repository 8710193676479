<template>
  <div ref="containerRef">
    <!-- lingpocket-web-display-ad -->
    <ins
      class="adsbygoogle"
      style="display: block"
      data-ad-client="ca-pub-7592561252966264"
      data-ad-slot="4200418434"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const containerRef = ref<HTMLDivElement>();

onMounted(() => {
  const adScript = document.createElement('script');
  adScript.async = true;
  adScript.crossOrigin = 'anonymous';
  adScript.src =
    'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7592561252966264';

  const inlineScript = document.createElement('script');
  inlineScript.type = 'text/javascript';
  inlineScript.text = '(adsbygoogle = window.adsbygoogle || []).push({});';

  containerRef.value?.appendChild(adScript);
  containerRef.value?.appendChild(inlineScript);
});
</script>
