<script setup lang="ts">
import { ref, computed } from 'vue';
import 'firebaseui/dist/firebaseui.css';
import { login } from '../../modules/login';
import { router } from '../../routes';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '../../stores/AppStore';
import AppFooter from '../organisms/AppFooter.vue';

const isLoginSuccessed = ref(false);
const { t } = useI18n();
const loginFailedMsg = computed(() => t('messages.auth.login.failedToLogin'));
const loginSuccessedMsg = computed(() =>
  t('messages.auth.login.successedToLogin')
);
const appStore = useAppStore();

const loginAction = async () => {
  try {
    await login();
    isLoginSuccessed.value = true;
    appStore.setAppMessage({
      type: 'success',
      msg: loginSuccessedMsg.value
    });
    router.replace('/');
  } catch (e) {
    appStore.setAppMessage({
      type: 'denger',
      msg: loginFailedMsg.value
    });
  }
};
</script>

<template>
  <div class="h-screen w-full flex justify-center items-center flex-col">
    <h1 class="text-4xl font-bold">LingPocket</h1>
    <button
      class="m-16 p-2 border-2 border-solid border-black border-opacity-25 hover:border-opacity-80 rounded-md cursor-pointer"
      data-testid="test-login-button"
      @click="loginAction"
    >
      {{ $t('loginPage.googleLoginButtonText') }}
    </button>
  </div>
  <AppFooter />
</template>
