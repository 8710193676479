<script setup lang="ts">
import { computed, ref } from 'vue';
import { WordItem } from '../../interfaces/wordItem';
import { updateUserWordFavorirte } from '../../modules/updateUserWordFavorirte';
import { addRememberedDate } from '../../modules/addRememberedDate';
import { deleteUserWord } from '../../modules/deleteUserWord';
import WordWditForm from './WordEditForm.vue';
import { useMyWordListPageStore } from '../../stores/myWordListPage.store';
import RememberDateStamp from '../atoms/RememberDateStamp.vue';
import { dateFormat } from '../../modules/dateFormat';
import DisplayAd from './DisplayAd.vue';
import { getAuth } from 'firebase/auth';
import { router } from '../../routes';
import { useI18n } from 'vue-i18n';
import { useFavoriteWordPageStore } from '../../stores/favoriteWordPage.store';

interface Emits {
  (e: 'showModal', word: WordItem | null, status: boolean): void;
}

const { t } = useI18n();

const props = defineProps<{ wordItem: WordItem; pageId: string }>();
const emit = defineEmits<Emits>();
const myWordListPageStore = useMyWordListPageStore();
const { words } = myWordListPageStore;
const favoriteWordPageStore = useFavoriteWordPageStore();
const { favoriteWords } = favoriteWordPageStore;
const wordItem = ref(props.wordItem);
const pageId = ref(props.pageId);
const isEditMode = ref<boolean>(false);
const editWordText = ref<string>(props.wordItem.word);
const isFavorite = ref<boolean>(props.wordItem.isFavorite);

const favoriteAction = async () => {
  try {
    const auth = getAuth();
    const authId = auth.currentUser?.uid;
    if (!authId) {
      router.replace('/login');
      return;
    }
    await updateUserWordFavorirte(authId, props.wordItem);
    isFavorite.value = !isFavorite.value;

    if (pageId.value === 'favorite-words-page') {
      favoriteWords.forEach((item, index) => {
        if (item.id === wordItem.value.id) {
          favoriteWords.splice(index, 1);
        }
      });
      emit('showModal', null, false);
    }
  } catch (e) {
    /* empty */
  }
};

const editModeAction = () => {
  isEditMode.value = !isEditMode.value;
};

const deleteHandler = async () => {
  const currentLang = window.navigator.language;
  const msg =
    currentLang === 'ja'
      ? `"${wordItem.value.word}"を削除してもいいですか？`
      : `Are you Sure to Delete "${wordItem.value.word}"`;

  const result = confirm(msg);
  if (result) {
    try {
      const auth = getAuth();
      const authId = auth.currentUser?.uid;
      if (!authId) {
        router.replace('/login');
        return;
      }
      await deleteUserWord(authId, wordItem.value);
      if (pageId.value === 'favorite-words-page') {
        favoriteWords.forEach((item, index) => {
          if (item.id === wordItem.value.id) {
            favoriteWords.splice(index, 1);
          }
        });
        favoriteWordPageStore.setMessage({
          type: 'success',
          content: 'messages.word.deleteWord.successedToDeleteWord'
        });
      } else {
        words.forEach((item, index) => {
          if (item.id === wordItem.value.id) {
            words.splice(index, 1);
          }
        });
        myWordListPageStore.setMessage({
          type: 'success',
          content: 'messages.word.deleteWord.successedToDeleteWord'
        });
      }
    } catch (e) {
      if (pageId.value === 'favorite-words-page') {
        favoriteWordPageStore.setMessage({
          type: 'denger',
          content: 'messages.word.deleteWord.failedToDeleteWord'
        });
      } else {
        myWordListPageStore.setMessage({
          type: 'denger',
          content: 'messages.word.deleteWord.failedToDeleteWord'
        });
      }
    }
    emit('showModal', null, false);
  }
};
const today = new Date();
const isRememberedIndex = computed(() =>
  wordItem.value.rememberedDate.findIndex(
    (date) => dateFormat(date) == dateFormat(today)
  )
);
const buttonTitle = computed(() =>
  isRememberedIndex.value === -1
    ? t('wordListPage.wordDetailModal.rememberButtonText')
    : t('wordListPage.wordDetailModal.forgetButtonText')
);

const buttonStyle = computed(() =>
  isRememberedIndex.value === -1
    ? 'text-green-600 hover:text-green-600'
    : 'text-yellow-600 hover:text-yellow-600'
);
const remember_forgot_DateHandller = () => {
  const auth = getAuth();
  const authId = auth.currentUser?.uid;
  if (!authId) {
    router.replace('/login');
    return;
  }
  if (isRememberedIndex.value === -1) {
    try {
      wordItem.value.rememberedDate.push(new Date());
      addRememberedDate(authId, wordItem.value);
    } catch (_e) {
      /* empty */
    }
  } else {
    wordItem.value.rememberedDate.splice(isRememberedIndex.value, 1);
    addRememberedDate(authId, wordItem.value);
  }
};
</script>
<template>
  <div
    id="word-detail-modal"
    class="modal fade fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto z-10"
  >
    <div
      class="modal fixed top-0 left-0 w-full h-full bg-zinc-500 opacity-50"
      tabindex="-1"
      aria-labelledby="word-detail-moda"
      aria-hidden="true"
      @click="emit('showModal', null, false)"
    />
    <div
      class="modal-dialog h-screen w-screen flex justify-center items-center"
    >
      <div
        class="modal-content overflow-y-auto hidden-scrollbar border-none container mx-auto md:w-5/12 h-3/5 shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current p-4"
      >
        <div v-if="wordItem">
          <WordWditForm
            v-if="isEditMode"
            :page-id="pageId"
            :word-item="wordItem"
            @edit-mode-action="editModeAction"
            @edit-close-modal-action="emit('showModal', null, false)"
          />
          <div v-else>
            <div class="w-full h-full">
              <div class="flex justify-end">
                <button
                  data-testid="test-edit-button"
                  type="button"
                  class="btn-close box-content h-4 p-2 mr-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  @click="editModeAction"
                >
                  {{ $t('wordListPage.wordDetailModal.edit') }}
                </button>
                <button
                  type="button"
                  class="btn-close box-content h-4 p-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
                  @click="emit('showModal', null, false)"
                >
                  {{ $t('wordListPage.wordDetailModal.close') }}
                </button>
              </div>
            </div>
            <div class="flex place-content-between">
              <div class="flex">
                <span class="text-black p-2">{{
                  $t('wordListPage.wordDetailModal.word')
                }}</span>
                <div class="flex">
                  <input
                    v-if="isEditMode"
                    v-model="editWordText"
                    class="w-full mx-2 border-neutral-800 border-b-2 focus:outline-none px-2"
                  />
                  <div v-else class="text-black text-3xl font-extrabold">
                    {{ wordItem.word }}
                  </div>

                  <button class="pt-3 px-2" @click="favoriteAction">
                    <ion-icon
                      v-if="isFavorite"
                      name="star"
                      class="text-yellow-300"
                    />
                    <ion-icon v-else name="star-outline" />
                  </button>
                </div>
              </div>
            </div>
            <div class="modal-body relative p-2">
              <span class="text-black">{{
                $t('wordListPage.wordDetailModal.description')
              }}</span>
              <p v-if="wordItem.definition" class="text-black p-2">
                {{ wordItem.definition }}
              </p>
              <p v-else class="text-black p-2">
                {{ $t('wordListPage.wordDetailModal.notRegisterd') }}
              </p>
            </div>
            <div class="modal-body relative p-2">
              <span class="text-black">{{
                $t('wordListPage.wordDetailModal.memo')
              }}</span>
              <p v-if="wordItem.memo" class="text-black p-2">
                {{ wordItem.memo }}
              </p>
              <p v-else class="text-black p-2">
                {{ $t('wordListPage.wordDetailModal.notRegisterd') }}
              </p>
            </div>
            <div class="w-full p-2 flex justify-center">
              <DisplayAd />
            </div>
            <div class="flex flex-col modal-body relative p-2">
              <button
                :class="[
                  buttonStyle,
                  'btn-close box-content h-4 p-2 border-none rounded-none md:opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:opacity-100 hover:font-bold hover:no-underline'
                ]"
                @click="remember_forgot_DateHandller"
              >
                {{ buttonTitle }}
              </button>
              <span class="text-black">{{
                $t('wordListPage.wordDetailModal.rememberdDate')
              }}</span>
              <ul class="flex">
                <RememberDateStamp
                  v-for="date in wordItem.rememberedDate"
                  :key="dateFormat(date)"
                  :date="date"
                />
              </ul>
            </div>
            <div class="w-full flex justify-center">
              <button
                data-testid="test-delete-word-button"
                type="button"
                class="btn-close box-content text-rose-600 h-4 p-2 border-none rounded-none md:opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-rose-600 hover:opacity-100 hover:font-bold hover:no-underline"
                @click="deleteHandler"
              >
                {{ $t('wordListPage.wordDetailModal.delete') }}
              </button>
            </div>
          </div>
        </div>
        <div v-else class="flex place-content-between">
          <h3 class="p-2">{{ $t('wordDetailModal.failedToGetWord') }}</h3>
          <button
            type="button"
            class="btn-close box-content h-4 p-2 text-black border-none rounded-none opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
            @click="emit('showModal', null, false)"
          >
            close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
