<template>
  <button
    data-testid="test-form-button"
    class="w-full bg-zinc-600 p-3 rounded text-white opacity-75 hover:font-bold hover:opacity-100"
  >
    {{ $t('wordListPage.wordRegister.registerButtonText') }}
  </button>
</template>

<style scoped>
:disabled {
  cursor: not-allowed;
  opacity: 0.75;
  font-weight: 100;
}
</style>
