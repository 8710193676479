import { defineStore } from 'pinia';
import { Message } from '../interfaces/message';

interface SettingPageState {
  message: Message | null;
}
export const useSettingPageStore = defineStore('settingPageStore', {
  state: (): SettingPageState => {
    return { message: null };
  },
  actions: {
    setMessage(message: Message | null) {
      this.message = message;
    }
  }
});
