import { updateDoc, doc, FirestoreError } from 'firebase/firestore';
import { db } from '../firebase';
import { WordItem } from '../interfaces/wordItem';
import {
  usersCollectionName,
  usersEnglishWordsCollectionName
} from '../globalDefinition';
import { WordError } from '../core/errors/wordError';
import * as Sentry from '@sentry/vue';

export const addRememberedDate = async (
  authId: string,
  data: WordItem
): Promise<boolean> => {
  if (!data.id) {
    const e = new WordError('Word-addRememberedDate-' + 'WordItem is null');
    throw e;
  }
  const updateRef = doc(
    db,
    usersCollectionName,
    authId,
    usersEnglishWordsCollectionName,
    data.id
  );

  try {
    await updateDoc(updateRef, { rememberedDate: data.rememberedDate });
    return true;
  } catch (error: unknown) {
    if (error instanceof FirestoreError) {
      const firestoreError = new WordError(
        'Word-addRememberedDate-' + error.message
      );
      throw firestoreError;
    }
    Sentry.captureException(error);
    const e = new WordError('Word-addRememberedDate-' + 'Unexpected exception');
    throw e;
  }
};
