import { createI18n } from 'vue-i18n';
import localazation from './assets/i18n';

const browserLanguage = navigator.language;

export const i18n = createI18n({
  legacy: false,
  locale: browserLanguage === 'ja' ? 'ja' : 'en',
  messages: localazation
});
