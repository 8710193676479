import { db } from '../firebase';
import { doc, deleteDoc, FirestoreError } from 'firebase/firestore';
import { WordItem } from '../interfaces/wordItem';
import {
  usersCollectionName,
  usersEnglishWordsCollectionName
} from '../globalDefinition';
import { WordError } from '../core/errors/wordError';
import * as Sentry from '@sentry/vue';

export const deleteUserWord = async (
  authId: string,
  wordItem: WordItem
): Promise<void> => {
  if (wordItem.id == null) throw new Error('Word Item UID is not exist.');
  const wordDoc = doc(
    db,
    usersCollectionName,
    authId,
    usersEnglishWordsCollectionName,
    wordItem.id
  );
  try {
    await deleteDoc(wordDoc);
  } catch (error: unknown) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new WordError(
        'Word-deleteUserWord-' + error.message
      );
      throw firestoreError;
    }
    const e = new WordError('Word-deleteUserWord-' + 'Unexpected exception');

    throw e;
  }
};
