import { defineStore } from 'pinia';
import { Message } from '../interfaces/message';
import { WordItem } from '../interfaces/wordItem';

interface myWordListPageState {
  message: Message | null;
  words: WordItem[];
  isLoading: boolean;
}
export const useMyWordListPageStore = defineStore('myWordListPageStore', {
  state: (): myWordListPageState => {
    return { message: null, words: [], isLoading: true };
  },
  actions: {
    setMessage(message: Message | null) {
      this.message = message;
    },
    setWords(words: WordItem[]) {
      this.words = words;
    },
    setLoadiinfStatus(status: boolean) {
      this.isLoading = status;
    }
  }
});
