import { addDoc, collection, FirestoreError } from 'firebase/firestore';
import { db } from '../firebase';
import { WordItem } from '../interfaces/wordItem';
import {
  usersCollectionName,
  usersEnglishWordsCollectionName
} from '../globalDefinition';
import { WordError } from '../core/errors/wordError';
import * as Sentry from '@sentry/vue';

export const addUserWord = async (
  authId: string,
  data: WordItem
): Promise<WordItem> => {
  const collectionRef = collection(
    db,
    usersCollectionName,
    authId,
    usersEnglishWordsCollectionName
  );

  try {
    const wordRef = await addDoc(collectionRef, data);
    if (wordRef.id == null)
      throw new WordError(
        'Word-addUserWord-' + 'Word ref does not have an ID.'
      );
    return { ...data, id: wordRef.id };
  } catch (error: unknown) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new WordError('Word-addUserWord-' + error.message);
      throw firestoreError;
    }
    const e = new WordError('Word-addUserWord-' + 'Unexpected exception');

    throw e;
  }
};
