import { defineStore } from 'pinia';
import { AppUser } from '../interfaces/AppUser';

export type MsgType = 'warn' | 'denger' | 'success';

export interface AppMessage {
  type: MsgType;
  msg: string;
}

interface AppState {
  currentUser: AppUser | null;
  appMessage: AppMessage | null;
}

export const useAppStore = defineStore('appStore', {
  state: (): AppState => {
    return { currentUser: null, appMessage: null };
  },
  actions: {
    setCurrentUser(currentUser: AppUser | null) {
      this.currentUser = currentUser;
    },
    setAppMessage(msg: AppMessage | null) {
      this.appMessage = msg;
    }
  }
});
