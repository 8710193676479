<script setup lang="ts">
import { ref, computed } from 'vue';
import { WordItem } from '../../interfaces/wordItem';
import { updateUserWord } from '../../modules/updateUserWord';
import { english_alphabets_table } from '../../commonData';
import { useMyWordListPageStore } from '../../stores/myWordListPage.store';
import { getAuth } from 'firebase/auth';
import { router } from '../../routes';
import { useFavoriteWordPageStore } from '../../stores/favoriteWordPage.store';

interface Emits {
  (e: 'editModeAction'): void;
  (e: 'edit-close-modal-action'): void;
}

const props = defineProps<{ wordItem: WordItem; pageId: string }>();
const pageId = ref(props.pageId);
const emit = defineEmits<Emits>();

const editWordText = ref<string>(props.wordItem.word);
const editDefinitionText = ref<string>(props.wordItem.definition);
const editMemoText = ref<string>(props.wordItem.memo);

const isNotUpdateAble = computed(
  () => editWordText.value === '' || editDefinitionText.value === ''
);
const myWordListPageStore = useMyWordListPageStore();
const favoriteWordPageStore = useFavoriteWordPageStore();

const updateWordAction = async () => {
  const firstLatter = editWordText.value.split('').at(0);
  const belongTo = english_alphabets_table.includes(
    (firstLatter ?? '').toLocaleUpperCase()
  )
    ? (firstLatter ?? '').toLocaleUpperCase()
    : 'OTHER';

  const editedWord: WordItem = {
    ...props.wordItem,
    word: editWordText.value,
    belongTo,
    definition: editDefinitionText.value,
    memo: editMemoText.value,
    updatedAt: new Date()
  };
  try {
    const auth = getAuth();
    const authId = auth.currentUser?.uid;
    if (!authId) {
      router.replace('/login');
      return;
    }
    const updatedWord = await updateUserWord(authId, editedWord);
    if (pageId.value === 'favorite-words-page') {
      favoriteWordPageStore.favoriteWords.forEach((item, index) => {
        if (item.id === updatedWord.id) {
          favoriteWordPageStore.favoriteWords[index] = updatedWord;
        }
      });
      favoriteWordPageStore.setMessage({
        type: 'success',
        content: 'messages.word.updateWord.successedToUpdateWord'
      });
    } else {
      myWordListPageStore.words.forEach((item, index) => {
        if (item.id === updatedWord.id) {
          myWordListPageStore.words[index] = updatedWord;
        }
      });
      myWordListPageStore.setMessage({
        type: 'success',
        content: 'messages.word.updateWord.successedToUpdateWord'
      });
    }
  } catch (_ignore) {
    if (pageId.value === 'favorite-words-page') {
      favoriteWordPageStore.setMessage({
        type: 'denger',
        content: 'messages.word.updateWord.failedToUpdateWord'
      });
    } else {
      myWordListPageStore.setMessage({
        type: 'denger',
        content: 'messages.word.updateWord.failedToUpdateWord'
      });
    }
  }
  emit('edit-close-modal-action');
};
</script>

<template>
  <div class="flex flex-col place-content-between">
    <div class="w-full grid justify-items-end">
      <button @click="emit('editModeAction')">
        {{ $t('wordListPage.wordEditModal.cancel') }}
      </button>
    </div>
    <div class="modal-body relative p-2">
      <span class="text-black">
        {{ $t('wordListPage.wordEditModal.word') }}</span
      >
      <input
        v-model="editWordText"
        data-testid="test-edit-word-input"
        class="w-full h-10 border rounded p-2"
      />
    </div>
  </div>
  <div class="modal-body relative p-2">
    <span class="text-black">
      {{ $t('wordListPage.wordEditModal.description') }}</span
    >
    <textarea
      v-model="editDefinitionText"
      data-testid="test-edit-definition-input"
      class="w-full border rounded p-2 h-32"
    />
  </div>
  <div class="modal-body relative p-2">
    <span class="text-black"> {{ $t('wordListPage.wordEditModal.memo') }}</span>
    <textarea
      v-model="editMemoText"
      data-testid="test-edit-memo-input"
      class="w-full border rounded p-2 h-32"
    />
  </div>
  <div class="flex">
    <button
      data-testid="test-edit-submit-button"
      class="w-full bg-zinc-600 p-3 rounded text-white opacity-75 hover:font-bold hover:opacity-100"
      :disable="isNotUpdateAble"
      @click="updateWordAction"
    >
      {{ $t('wordListPage.wordEditModal.update') }}
    </button>
  </div>
</template>
