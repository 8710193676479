<template>
  <li class="flex flex-col-reverse m-2 p-2 items-center">
    <ion-icon size="large" name="checkbox-outline" />{{ rememberDate }}
  </li>
</template>
<script setup lang="ts">
import { dateFormat } from '../../modules/dateFormat';
interface Props {
  date: Date;
}

const props = defineProps<Props>();
const rememberDate = dateFormat(props.date);
</script>
