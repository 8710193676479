<template>
  <div class="h-auto w-full flex pt-4">
    <div class="w-full">
      <ul class="flex">
        <li
          :class="[
            wordTitleStyle,
            'p-2 opacity-75 hover:font-bold hover:opacity-100'
          ]"
        >
          <a href="/">{{ $t('common.wordMenuBar.word.title') }}</a>
        </li>
        <li
          :class="[
            favoriteWordTitleStyle,
            'p-2 opacity-75 hover:font-bold hover:opacity-90'
          ]"
        >
          <a href="/words/favorite">
            {{ $t('common.wordMenuBar.favoriteWord.title') }}</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';
import { router } from '../../routes';

const fullPath = router.currentRoute.value.fullPath;
const wordTitleStyle = computed(() => fullPath === '/' && 'font-bold');
const favoriteWordTitleStyle = computed(
  () => fullPath === '/words/favorite' && 'font-bold'
);
</script>
