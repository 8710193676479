<script setup lang="ts">
import { computed, ref } from 'vue';
import { MsgType } from '../../stores/AppStore';
import { XMarkIcon } from '@heroicons/vue/24/solid';

interface Props {
  type: MsgType;
  msg: string;
  closeAction: () => void;
}
const isOpen = true;
const isClose = ref<boolean>(isOpen);

const props = defineProps<Props>();

const closeAction = () => {
  props.closeAction();
  isClose.value = false;
};

const bannerBackgroundColor = computed(() => {
  let bgColor = '';
  switch (props.type) {
    case 'success':
      bgColor = 'bg-emerald-500';
      break;
    case 'warn':
      bgColor = 'bg-amber-300';
      break;
    case 'denger':
      bgColor = 'bg-red-400';
      break;
    default:
      return bgColor;
  }
  return bgColor;
});
</script>

<template>
  <div
    v-if="isClose"
    class="w-screen py-3 px-12 text-white font-bold h-12 flex place-content-between"
    data-testid="test-message-banner"
    :class="bannerBackgroundColor"
  >
    <div data-testid="test-message">
      {{ $t(`${props.msg}`) }}
    </div>
    <button data-testid="test-message-close-button" @click="closeAction">
      <XMarkIcon class="h-6 w-6" />
    </button>
  </div>
</template>
