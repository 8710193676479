<template>
  <div class="flex flex-col">
    <span class="text-xs font-bold">{{
      $t('common.accountDeleteModal.validation.formTitle')
    }}</span>
    <input
      class="border-2 p-1 rounded w-64"
      :value="inputContent"
      :placeholder="placeholder"
      @input="inputHandller"
      v-on:focusout="emit('callback', inputContent)"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
type Props = {
  placeholder?: string;
};

const props = defineProps<Props>();
// eslint-disable-next-line vue/no-setup-props-destructure
const { placeholder = '' } = props;

interface Emits {
  (e: 'callback', inputValue: string): void;
}
const emit = defineEmits<Emits>();
const inputContent = ref('');

const inputHandller = (e: Event) => {
  e.preventDefault();
  if (e.target == null || !(e.target instanceof HTMLInputElement)) return;
  inputContent.value = e.target.value;
  emit('callback', inputContent.value);
};
</script>
