<script setup lang="ts">
import AppHeader from '../organisms/AppHeader.vue';
import AppFooter from '../organisms/AppFooter.vue';
import MsgBanner from '../atoms/MsgBanner.vue';
import { ref, onMounted, computed } from 'vue';
import { updateUserName } from '../../modules/updateUserName';
import DeleteUserAxountModal from '../organisms/DeleteUserAccountModal.vue';
import { useSettingPageStore } from '../../stores/settingPage.store';
import { dateFormat } from '../../modules/dateFormat';
import { useDeleteUserAccountModalStore } from '../../stores/deleteUserAccountModal.store';
import { getAuth } from 'firebase/auth';
import { AppUser } from '../../interfaces/AppUser';
import { useAppStore } from '../../stores/AppStore';
import { router } from '../../routes';

const settingPageStore = useSettingPageStore();
const appStore = useAppStore();

const currentUser = ref<AppUser | null>(null);
const isUpdated = ref<boolean | null>(null);
const userNameInput = ref<string>('');

const isEditName = ref<boolean>(false);

const changeStateOfIsEditName = () => {
  isEditName.value = !isEditName.value;
};

const updateName = async () => {
  isUpdated.value = null; //初期化
  const name = userNameInput.value;
  try {
    const auth = getAuth();
    const authId = auth.currentUser?.uid;
    if (!authId) {
      router.replace('/login');
      return;
    }
    await updateUserName(authId, name);
    settingPageStore.setMessage({
      type: 'success',
      content: 'messages.user.updateUserName.successedToUpdateUserName'
    });
  } catch (e) {
    settingPageStore.setMessage({
      type: 'denger',
      content: 'messages.user.updateUserName.failedToUpdateUserName'
    });
  }
  isEditName.value = false;
};

onMounted(async () => {
  try {
    const auth = getAuth();
    const authUser = auth.currentUser;
    const storedCurrentUser = appStore.currentUser;
    if (!authUser || !storedCurrentUser) {
      router.replace('/login');
      return;
    }

    currentUser.value = storedCurrentUser;

    userNameInput.value = storedCurrentUser?.name || '';
  } catch (e) {
    settingPageStore.setMessage({
      type: 'denger',
      content: 'messages.user.getUserInfo.failedToGetUserInfo'
    });
  }
});

const deleteUserAccountModalStore = useDeleteUserAccountModalStore();

const deleteUserAccountHanddler = () => {
  deleteUserAccountModalStore.setDeleteUserAccountModalStatus(true);
};

const closeModalHanddler = () => {
  deleteUserAccountModalStore.setDeleteUserAccountModalStatus(false);
};

const createdAt = computed(() =>
  currentUser?.value?.createdAt
    ? dateFormat(currentUser?.value?.createdAt)
    : '-'
);
const updatedAt = computed(() =>
  currentUser?.value?.updatedAt
    ? dateFormat(currentUser?.value?.updatedAt)
    : '-'
);
const appModalOptions = {
  validateForm: true
};

const closeMessage = () => {
  settingPageStore.setMessage(null);
};
</script>

<template>
  <AppHeader />
  <div class="h-screen md:h-[calc(100vh_-_48px_-_80px)]">
    <div
      v-if="settingPageStore.message?.type && settingPageStore.message?.content"
    >
      <MsgBanner
        :type="settingPageStore.message?.type"
        :msg="settingPageStore.message?.content"
        :close-action="closeMessage"
      />
    </div>
    <div
      class="flex-col md:h-full w-full flex p-2 md:p-0 md:justify-center md:items-center"
    >
      <div class="h-auto md:w-1/2 border-4 rounded">
        <div class="w-full p-2 border-b-2 font-bold">
          <h2>{{ $t('settingPage.title') }}</h2>
        </div>
        <div class="w-full px-6 py-2 border-b-2">
          <div class="flex w-full">
            <span class="font-bold">{{ $t('settingPage.userName') }} </span>
            <div class="flex justify-between w-5/6">
              <input
                v-if="isEditName"
                v-model="userNameInput"
                data-testid="test-setting-page-user-name-input"
                class="w-full mx-2 border-neutral-800 border-b-2 focus:outline-none px-2"
              />
              <div
                v-else
                class="ml-2"
                data-testid="test-setting-page-user-name"
              >
                {{ userNameInput }}
              </div>
              <div>
                <button
                  v-if="isEditName"
                  data-testid="test-setting-page-update-user-name-button"
                  @click="updateName"
                >
                  {{ $t('settingPage.updateNameButtonText') }}
                </button>
                <button
                  v-else
                  data-testid="test-setting-page-edit-user-name-button"
                  @click="changeStateOfIsEditName"
                >
                  {{ $t('settingPage.editNameButtonText') }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full px-6 py-2 border-b-2">
          <div>
            <span class="font-bold">{{ $t('settingPage.createdAt') }} </span
            ><span class="ml-2">{{ createdAt }}</span>
          </div>
        </div>
        <div class="w-full px-6 py-2 border-b-2">
          <div>
            <span class="font-bold">{{ $t('settingPage.updatedAt') }}</span
            ><span class="ml-2">{{ updatedAt }}</span>
          </div>
        </div>
      </div>
      <div class="h-auto md:w-1/2 text-center p-12">
        <button
          data-testid="test-delete-word-button"
          type="button"
          class="btn-close box-content text-rose-600 h-4 p-2 border-none rounded-none md:opacity-50 focus:shadow-none focus:outline-none focus:opacity-100 hover:text-rose-600 hover:opacity-100 hover:font-bold hover:no-underline"
          v-on:click="deleteUserAccountHanddler"
        >
          {{ $t('settingPage.deleteAccountButtonText') }}
        </button>
      </div>
      <DeleteUserAxountModal
        :options="appModalOptions"
        @action="closeModalHanddler"
        :is-shown="deleteUserAccountModalStore.isModalShown"
        v-if="deleteUserAccountModalStore.isModalShown"
      />
    </div>
  </div>
  <AppFooter />
</template>
