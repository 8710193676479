<script setup lang="ts">
import { footerMenus } from '../../commonData';
import { useAppStore } from '../../stores/AppStore';
import { useI18n } from 'vue-i18n';

const appStore = useAppStore();
const i18n = useI18n();

let menuItems: { title: string; link: string }[];
if (!appStore.currentUser) {
  menuItems = footerMenus;
} else {
  const contactUrl =
    i18n.locale.value == 'ja'
      ? 'https://docs.google.com/forms/d/e/1FAIpQLSdjEwaraXELOm7QinJ8dxI1qHy90UC6Vox9_5vE36bQCMcsiA/viewform?usp=sf_link'
      : 'https://docs.google.com/forms/d/e/1FAIpQLScBwwsKn09OuOhTDxnTeU2QrldaRwve6KovShovHD7XMNPaNQ/viewform?usp=sf_link';

  footerMenus.push({
    title: 'linkContact',
    link: contactUrl
  });
  menuItems = footerMenus;
}
</script>

<template>
  <nav
    class="md:h-12 w-full flex items-center md:justify-end flex-wrap bg-zinc-700 md:p-4 opacity-50"
  >
    <ul class="flex flex-col md:flex-row text-slate-50 text-sm w-fill">
      <li
        v-for="menu in menuItems"
        :key="menu.title"
        class="px-2 py-2 md:px-0 md:py-0 md:ml-4 hover:font-bold"
      >
        <a :href="menu.link">{{ $t(`common.footer.${menu.title}`) }}</a>
      </li>
    </ul>
  </nav>
</template>
