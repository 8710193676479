import WordListPage from './components/pages/MyWordListPage.vue';
import UserSettingPage from './components/pages/SettingPage.vue';
import NotFoundPage from './components/pages/NotFoundPage.vue';
import InternalServerErrorPage from './components/pages/InternalServerErrorPage.vue';
import LoginPage from './components/pages/LoginPage.vue';
import FavoriteWordListPage from './components/pages/FavoriteWordListPage.vue';
import { createRouter, createWebHistory } from 'vue-router';
import { useAppStore } from './stores/AppStore';
import { getCurrentUser } from './modules/getCurrentUser';

const routes = [
  {
    name: 'Word',
    path: '/',
    component: WordListPage,
    meta: { isPrivate: true }
  },
  {
    name: 'FavoriteWord',
    path: '/words/favorite',
    component: FavoriteWordListPage,
    meta: { isPrivate: true }
  },
  {
    name: 'NotFound',
    path: '/:catchAll(.*)',
    component: NotFoundPage
  },
  { name: 'Login', path: '/login', component: LoginPage },
  {
    name: 'Setting',
    path: '/user/setting',
    component: UserSettingPage,
    meta: { isPrivate: true }
  },
  {
    name: 'InternalServerErrorPage',
    path: '/error',
    component: InternalServerErrorPage
  }
];

export const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  const appStore = useAppStore();
  const currentUser = await getCurrentUser();

  appStore.setCurrentUser(currentUser);
  const isPrivate = to.matched.some((record) => record.meta.isPrivate);
  if (currentUser != null && to.name === 'Login') {
    next({ path: '/' });
  }
  if (
    isPrivate &&
    !appStore.currentUser &&
    typeof to.name != 'undefined' &&
    to.name !== 'Login'
  ) {
    next({ path: '/login' });
  }
  next();
});
