<script setup lang="ts">
import { ref, computed } from 'vue';
import WordAddFormButton from '../atoms/WordAddFormButton.vue';
import { WordItem } from '../../interfaces/wordItem';
import { english_alphabets_table } from '../../commonData';
import { addUserWord } from '../../modules/addUserWord';
import { MsgType } from '../../interfaces/message';
import { getAuth } from 'firebase/auth';
import { router } from '../../routes';
import { useMyWordListPageStore } from '../../stores/myWordListPage.store';

const word = ref<string>('');
const definition = ref<string>('');
const memo = ref<string>('');
const isNotAddable = computed(
  () => word.value === '' || definition.value === ''
);

const myWordListPageStore = useMyWordListPageStore();

const addWordHandler = async () => {
  const firstLatter = word.value.split('').at(0);
  const belongTo = english_alphabets_table.includes(
    (firstLatter ?? '').toLocaleUpperCase()
  )
    ? (firstLatter ?? '').toLocaleUpperCase()
    : 'OTHER';
  const data: WordItem = {
    word: word.value,
    definition: definition.value,
    memo: memo.value,
    isFavorite: false,
    belongTo,
    rememberedDate: [],
    createdAt: new Date(),
    updatedAt: new Date()
  };
  try {
    const auth = getAuth();
    const authId = auth.currentUser?.uid;
    if (!authId) {
      router.replace('/login');
      return;
    }
    const newWord = await addUserWord(authId, data);
    const newItems = [...myWordListPageStore.words, newWord].sort((a, b) =>
      a.word.localeCompare(b.word)
    );
    myWordListPageStore.setWords(newItems);
    word.value = '';
    definition.value = '';
    memo.value = '';
    const msg = {
      type: 'success' as MsgType,
      content: 'messages.word.registerWord.successedToAddWord'
    };
    myWordListPageStore.setMessage(msg);
  } catch (_e) {
    const errorMsg = {
      type: 'denger' as MsgType,
      content: 'messages.word.registerWord.failedToAddWord'
    };
    myWordListPageStore.setMessage(errorMsg);
  }
};
</script>
<template>
  <div class="flex flex-col border rounded p-4">
    <div class="p-2 w-full">
      {{ $t('wordListPage.wordRegister.title') }}
    </div>
    <div class="p-2">
      <input
        v-model="word"
        data-testid="test-input-word"
        class="w-full h-10 border rounded p-2"
        :placeholder="$t('wordListPage.wordRegister.wordTextPlaceholder')"
      />
    </div>
    <div class="p-2">
      <textarea
        v-model="definition"
        data-testid="test-input-definition"
        class="w-full border rounded p-2 h-32"
        :placeholder="$t('wordListPage.wordRegister.definitionTextPlaceholder')"
      />
    </div>
    <div class="p-2">
      <textarea
        v-model="memo"
        data-testid="test-input-memo"
        class="w-full border rounded p-2 h-32"
        :placeholder="$t('wordListPage.wordRegister.memoTextPlaceholder')"
      />
    </div>
    <div class="p-2">
      <WordAddFormButton
        data-testid="test-add-word-button"
        :disabled="isNotAddable"
        @click="addWordHandler"
      />
    </div>
  </div>
</template>
