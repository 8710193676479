<template>
  <AppHeader />
  <div class="h-screen md:h-[calc(100%_-_48px_-_80px)]">
    <div
      v-if="
        favoriteWordPageStore.message?.type &&
        favoriteWordPageStore.message?.content
      "
    >
      <Banner
        :type="favoriteWordPageStore.message?.type"
        :msg="favoriteWordPageStore.message?.content"
        :close-action="closeMessage"
      />
    </div>
    <div class="container h-full w-full mx-auto">
      <WordsMenuBar />
      <div class="h-full">
        <div class="md:basis-6/6 px-2 pt-4">
          <FavoriteWordList />
        </div>
      </div>
    </div>
  </div>
  <AppFooter />
</template>

<script setup lang="ts">
import AppHeader from '../organisms/AppHeader.vue';
import AppFooter from '../organisms/AppFooter.vue';
import FavoriteWordList from '../organisms/FavoriteWordList.vue';
import WordsMenuBar from '../molecules/WordsMenuBar.vue';
import Banner from '../atoms/MsgBanner.vue';
import { useFavoriteWordPageStore } from '../../stores/favoriteWordPage.store';
import { onMounted } from 'vue';
import { getUserFavoriteWords } from '../../modules/getUserFavoriteWords';
import { MsgType } from '../../stores/AppStore';
import { getAuth } from 'firebase/auth';
import { router } from '../../routes';
const favoriteWordPageStore = useFavoriteWordPageStore();

onMounted(async () => {
  try {
    const auth = getAuth();
    const authId = auth.currentUser?.uid;
    if (!authId) {
      router.replace('/login');
      return;
    }
    const favoriteWords = await getUserFavoriteWords(authId);
    favoriteWordPageStore.setFavoriteWords(favoriteWords);
    favoriteWordPageStore.setLoadingStatus(false);
  } catch (e) {
    const errorMsg = {
      type: 'denger' as MsgType,
      content: 'messages.favoriteWord.getUserWords.failedToGetWordList'
    };
    favoriteWordPageStore.setMessage(errorMsg);
  }
});

const closeMessage = () => {
  favoriteWordPageStore.setMessage(null);
};
</script>
