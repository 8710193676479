<script setup lang="ts">
import { ref, computed } from 'vue';
import WordDetailModal from '../organisms/WordDetailModal.vue';
import { WordItem } from '../../interfaces/wordItem';
import { useFavoriteWordPageStore } from '../../stores/favoriteWordPage.store';

const selectedWord = ref<WordItem | null>(null);
const isShow = ref<boolean>(false);

const favoriteWordPageStore = useFavoriteWordPageStore();
const userFavoriteItems = computed(() => favoriteWordPageStore.favoriteWords);

const isLoading = computed(() => favoriteWordPageStore.isLoading);

const showModal = (word: WordItem | null, status: boolean): void => {
  isShow.value = status;
  if (word == null) return;
  selectedWord.value = word;
};

const isFavoriteWordsExist = computed(() => userFavoriteItems.value.length > 0);
</script>

<template>
  <div class="h-5/6 max-w-2xl overflow-y-auto hidden-scrollbar">
    <div v-if="isLoading">loading...</div>
    <div v-if="isFavoriteWordsExist">
      <ul class="list-none m-2">
        <li
          v-for="(item, index) in userFavoriteItems"
          :key="index"
          data-testid="test-word-item"
          class="m-2 p-2 border rounded border-slate-300 hover:border-slate-400"
          @click="showModal(item, true)"
        >
          {{ item.word }}
        </li>
      </ul>
    </div>
    <div v-else class="p-2">
      {{ $t('favoriteWordListPage.notExist') }}
    </div>
  </div>
  <div v-if="isShow && selectedWord != null">
    <WordDetailModal
      page-id="favorite-words-page"
      :word-item="selectedWord"
      @show-modal="showModal"
    />
  </div>
</template>
