import { createApp } from 'vue';
import './style.css';
import App from './App.vue';

import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { router } from './routes';
import { i18n } from './i18n';

const pinia = createPinia();

const app = createApp(App);
app.config.errorHandler = () => {
  router.replace({ name: 'InternalServerErrorPage' });
};

app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith('ion-');

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_ENTRY_DSN_URL,
  enabled: import.meta.env.MODE === 'production',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router)
    }),
    new Sentry.Replay()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

app.use(pinia);
app.use(router);
app.use(i18n);
app.mount('#app');
