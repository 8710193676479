import * as Sentry from '@sentry/vue';
export class BaseError extends Error {
  constructor(e?: string) {
    super(e);
    this.trace();
  }

  trace() {
    Sentry.captureException(this);
  }
}
