import { db } from '../firebase';

import {
  collection,
  FirestoreError,
  getDocs,
  query,
  Timestamp,
  where
} from 'firebase/firestore';
import { WordItem } from '../interfaces/wordItem';
import {
  usersCollectionName,
  usersEnglishWordsCollectionName
} from '../globalDefinition';
import { WordError } from '../core/errors/wordError';
import * as Sentry from '@sentry/vue';

export const getUserFavoriteWords = async (
  authId: string
): Promise<WordItem[]> => {
  const items: WordItem[] = [];
  try {
    const q = query(
      collection(
        db,
        usersCollectionName,
        authId,
        usersEnglishWordsCollectionName
      ),
      where('isFavorite', '==', true)
      //無効: 範囲フィルタと最初の orderBy を異なるフィールドに使用 Firestoreの仕様
      // orderBy('word', 'asc')
    );
    const snapshots = await getDocs(q);
    const docs = snapshots.docs.map((doc) =>
      doc.data({ serverTimestamps: 'estimate' })
    );
    docs.forEach((doc, index) =>
      items.push({
        id: snapshots.docs[index].id,
        word: doc.word,
        definition: doc.definition,
        isFavorite: doc.isFavorite,
        belongTo: doc.belongTo,
        memo: doc.memo,
        rememberedDate: (doc.rememberedDate ?? []).map((date: Timestamp) =>
          date.toDate()
        ),
        createdAt: (doc.createdAt as Timestamp).toDate(),
        updatedAt: (doc.updatedAt as Timestamp).toDate()
      })
    );

    items.sort();
    return items;
  } catch (error: unknown) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new WordError(
        'Word-getUserFavoriteWords-' + error.message
      );
      throw firestoreError;
    }
    const e = new WordError(
      'Word-getUserFavoriteWords-' + 'Unexpected exception'
    );

    throw e;
  }
};
