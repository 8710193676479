import { db } from '../firebase';
import { FirestoreError, doc, deleteDoc } from 'firebase/firestore';
import { usersCollectionName } from '../globalDefinition';
import { UserError } from '../core/errors/userError';
import * as Sentry from '@sentry/vue';

export const deleteUserAccount = async (authId: string): Promise<void> => {
  const currentUserDocument = doc(db, usersCollectionName, authId);

  try {
    await deleteDoc(currentUserDocument);
  } catch (error) {
    Sentry.captureException(error);
    if (error instanceof FirestoreError) {
      const firestoreError = new UserError(
        'User-deleteUserAccount-' + error.message
      );
      throw firestoreError;
    }
    const e = new UserError('User-deleteUserAccount-' + 'Unexpected exception');

    throw e;
  }
};
