export const languages = ['English', 'Japanese'];

export const japanese_table = ['あ', 'い', 'う', 'え', 'お'];

export const english_alphabets_table = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

export const english_sections = [...english_alphabets_table, 'OTHER'];

const privacy_and_policy_link =
  import.meta.env.MODE === 'development'
    ? 'http://localhost/privacy-and-policy'
    : import.meta.env.MODE === 'staging'
    ? 'https://staging.ling-pocket.com/privacy-and-policy'
    : 'https://ling-pocket.com/privacy-and-policy';
const about_link =
  import.meta.env.MODE === 'development'
    ? 'http://localhost'
    : import.meta.env.MODE === 'staging'
    ? 'https://staging.ling-pocket.com'
    : 'https://ling-pocket.com';
export const footerMenus = [
  {
    //i18n 対応のためprefixにlinkをつけている
    title: 'linkAbout',
    link: about_link
  },
  {
    //i18n 対応のためprefixにlinkをつけている
    title: 'linkPrivacyAndPolicy',
    link: privacy_and_policy_link
  }
];
