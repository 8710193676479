<script setup lang="ts">
import { ref, computed } from 'vue';
import WordDetailModal from '../organisms/WordDetailModal.vue';
import { english_sections as sections } from '../../commonData';
import { searchWordStore } from '../../stores/search-word.store';
import { WordItem } from '../../interfaces/wordItem';
import { useMyWordListPageStore } from '../../stores/myWordListPage.store';
const selectedWord = ref<WordItem | null>(null);
const sectionAlphabets = ref<HTMLElement[]>();
const isShow = ref<boolean>(false);

const myWordListPageStore = useMyWordListPageStore();

const showModal = (word: WordItem | null, status: boolean): void => {
  isShow.value = status;
  if (word == null) return;
  selectedWord.value = word;
};

const searchedWords = computed(() => {
  const searchText = searchWordStore.searchText.toLocaleLowerCase();
  const regexp = new RegExp(searchText);
  if (searchText === '') {
    return myWordListPageStore.words;
  }
  const result = myWordListPageStore.words.filter((item) =>
    item.word.toLocaleLowerCase().match(regexp)
  );
  if (result.length > 0 && sectionAlphabets.value) {
    const index = sections.findIndex(
      (section) => section === result[0].belongTo
    );
    sectionAlphabets?.value[index].scrollIntoView();
  }
  return result;
});

const sectionWords = (alphabet: string) => {
  return searchedWords.value.filter(
    (item) => item.belongTo === alphabet.toLocaleUpperCase()
  );
};
</script>

<template>
  <div class="h-5/6 overflow-y-auto hidden-scrollbar">
    <div v-for="section in sections" :key="section">
      <section
        ref="sectionAlphabets"
        data-testid="test-section-item"
        class="border-b-2 bg-gray-500 rounded font-bold text-xl px-2 text-white opacity-75"
      >
        {{ section }}
      </section>

      <ul class="list-none m-2">
        <li
          v-for="(item, index) in sectionWords(section)"
          :key="index"
          data-testid="test-word-item"
          class="m-2 p-2 border rounded border-slate-300 hover:border-slate-400"
          @click="showModal(item, true)"
        >
          {{ item.word }}
        </li>
      </ul>
    </div>
  </div>
  <div v-if="isShow && selectedWord != null">
    <WordDetailModal
      page-id="word-list-page"
      :word-item="selectedWord"
      @show-modal="showModal"
    />
  </div>
</template>
