<script setup lang="ts">
import { RouterView } from 'vue-router';
import MsgBanner from './components/atoms/MsgBanner.vue';
import { useAppStore } from './stores/AppStore';
import { computed } from 'vue';

const appStore = useAppStore();
const isMessage = computed(() => appStore.appMessage?.msg);
const message = computed(() => appStore.appMessage?.msg || '');
const messageType = computed(() => appStore.appMessage?.type || 'denger');
const closeMessage = () => {
  appStore.setAppMessage(null);
};
</script>
<template>
  <div class="h-screen w-screen">
    <div class="h-screen w-screen">
      <MsgBanner
        v-if="isMessage"
        :type="messageType"
        :msg="message"
        :close-action="closeMessage"
      />
      <router-view />
    </div>
  </div>
</template>
