import { defineStore } from 'pinia';

interface DeleteUserAccountModalState {
  isModalShown: boolean;
}

export const useDeleteUserAccountModalStore = defineStore(
  'deleteUserAccountModalStore',
  {
    state: (): DeleteUserAccountModalState => {
      return { isModalShown: false };
    },
    actions: {
      setDeleteUserAccountModalStatus(status = false) {
        this.isModalShown = status;
      }
    }
  }
);
