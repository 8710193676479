<script setup lang="ts">
import { computed } from 'vue';
import AppHeader from '../organisms/AppHeader.vue';
import AppFooter from '../organisms/AppFooter.vue';
import Banner from '../atoms/MsgBanner.vue';
import WordList from '../organisms/WordList.vue';
import WordAddForm from '../organisms/WordAddForm.vue';
import WordsMenuBar from '../molecules/WordsMenuBar.vue';
import { useMyWordListPageStore } from '../../stores/myWordListPage.store';
import DisplayAd from '../organisms/DisplayAd.vue';
import { getAuth } from 'firebase/auth';
import { onMounted } from 'vue';
import { getUserWords } from '../../modules/getUserWords';
import { router } from '../../routes';
import { MsgType } from '../../stores/AppStore';

const myWordListPageStore = useMyWordListPageStore();

onMounted(async () => {
  try {
    const auth = getAuth();
    const authId = auth.currentUser?.uid;
    if (!authId) {
      router.replace('/login');
      return;
    }
    const data = await getUserWords(authId);
    myWordListPageStore.setWords(data);
    myWordListPageStore.setMessage(null);
  } catch (e) {
    const errorMsg = {
      type: 'denger' as MsgType,
      content: 'messages.word.getUserWords.failedToGetWordList'
    };
    myWordListPageStore.setMessage(errorMsg);
  }
  myWordListPageStore.setLoadiinfStatus(false);
});

const isLoading = computed(() => myWordListPageStore.isLoading);

const closeMessage = () => {
  myWordListPageStore.setMessage(null);
};
</script>

<template>
  <AppHeader />
  <div class="md:h-[calc(100vh_-_48px_-_80px)]">
    <div
      v-if="
        myWordListPageStore.message?.type &&
        myWordListPageStore.message?.content
      "
    >
      <Banner
        :type="myWordListPageStore.message?.type"
        :msg="myWordListPageStore.message?.content"
        :close-action="closeMessage"
      />
    </div>
    <div class="container h-full w-full mx-auto">
      <WordsMenuBar />
      <div class="flex overflow-auto flex-col-reverse md:flex-row h-full">
        <div class="md:basis-4/6 px-2 pt-4">
          <div v-if="isLoading">lading...</div>
          <WordList v-else />
        </div>
        <div class="md:basis-2/6 p-4">
          <WordAddForm />
          <div class="w-full p-2 h-64">
            <DisplayAd />
          </div>
        </div>
      </div>
    </div>
  </div>
  <AppFooter />
</template>
