import { onAuthStateChanged, signOut } from 'firebase/auth';
import { auth } from '../firebase';
import { AppUser } from '../interfaces/AppUser';
import { getServiceUser } from './getServiceUser';

export const getCurrentUser = (): Promise<AppUser | null> =>
  new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      async (user) => {
        unsubscribe();
        if (user) {
          const currentUser = getServiceUser(user.uid);
          resolve(currentUser);
        } else {
          await signOut(auth);
          resolve(null);
        }
      },
      reject
    );
  });
